.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

#myVideo{
  background-color: blueviolet;
}
.vjs-record .vjs-device-button.vjs-control{
  font-size: 0 !important;
}
.info__button{
  width: 10em;
font-family:   sans-serif; 
background: #00a359 !important;
  color: white !important;
border-radius: 20px !important;
border: none;
}

.info__button:hover {
  /* background-color: #03c56e  !important; */
  box-shadow:0 5px 20px rgba(0, 35, 49, 0.2) !important ;
  color: white;
}
.list__containe{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.selectable__item{
  padding: 0 23px;
  width: 25em;
  height: 36px;
  font-family: sans-serif;
  border: rgb(174, 173, 173);
  background-color: white !important;
  border-radius: 20px !important;
  color: #00a359 !important;
  margin: .25rem;
  display: flex;
  align-items: center;
  
}
.selectable__item:hover {
  cursor: pointer;
  /* background-color: #03c56e  !important; */
  background-color: #04d074 !important;
  color: white !important; 
  border: #01b866 !important;
}
.selectable__item__selected{
  margin: .25rem;
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 36px;
  width: 25em;
  font-family: sans-serif;
  border: rgb(174, 173, 173);
  background-color: #00a359 !important;
  border-radius: 20px !important;
  color: white !important;
}
.progress_bar{
  width: 100%;
}
.btn__quiz{
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background-color: rgba(68, 61, 246, 0.1) !important;
  border: none !important;
  margin-bottom: 1rem;
  color: #00a359 !important;
}
.btn__quiz:hover {
  box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
  color: #00a359 !important;
}

.loader {
  width: 60px;
  display: flex;
  justify-content: space-evenly;
}

.ball {
  list-style: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
}

.ball:nth-child(1) {
  animation: bounce-1 2.1s ease-in-out infinite;
}

@keyframes bounce-1 {
  50% {
    transform: translateY(-18px);
    background-color: #00a359;
  }
}

.ball:nth-child(2) {
  animation: bounce-3 2.1s ease-in-out 0.3s infinite;
}

@keyframes bounce-2 {
  50% {
    transform: translateY(-18px);
    background-color: #00a359;
  }
}

.ball:nth-child(3) {
  animation: bounce-3 2.1s ease-in-out 0.6s infinite;
}

@keyframes bounce-3 {
  50% {
    transform: translateY(-18px);
    background-color: #00a359;
  }
}

.btn__quiz__disabled{
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background-color: rgba(68, 61, 246, 0.1) !important;
  border: none !important;
  margin-bottom: 1rem;
  color: #00a359 !important;
  opacity: 0.5;
  cursor: not-allowed;
}


.MuiToolbar-gutters{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dash_client{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-left: -2rem !important;
}

.dash_client_tester{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 97%;
  margin-left: -3em;
  /*margin-top: 5rem !important; */
}

.render-Table-Tester{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.error{
  color: red;
  font-size: 0.8rem;
  font-family: 'Roboto', sans-serif;
}


.modalUpdatePhotoFooter{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10;
  padding-bottom: 20;
}

.profile_model_img{
  width: 7rem !important;
  object-fit: none !important;
}

.modalUpdatePasswordFooter{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20;
}


#modal_input{
  font-size: 1em;
  height: 50px;
  border-radius: 19px;
  border: solid 1px #d3d3d3 !important;
  color: #002C3F;
}

.adress_inputs{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  width: 100%;
}