

.profil_tester_container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 2rem;
}

.espace{
    padding: 3rem 0 0 1rem;
}

.client-tester-profile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;

}

.profile-btn{
    background-color: rgba(189, 189, 189, 0);
    font-size: 0.9rem;
    color: rgb(0, 128, 255);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: underline;
}

.profil_card{
    background-color: #F4F7F7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.158);
    padding: 2rem;
    margin: 3rem;
}

.profile_title{
    margin: 5rem 0 1rem 2rem;
    text-align: left;
}

.modal_update_img_title{
    margin-bottom: 2rem;
    
}

.profile_img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}   

.profileImage{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

.profile_input_tester{
    width: 20.5rem !important;
    outline: none;
    font-size: 1rem;
    border-radius: 10px !important;
}

.supprime_paragraphe{
    padding-left: 1.5rem;
    padding-right: .9rem;
}


@media screen and (max-width: 1500px){
    .profile_input_tester{
        width: 18rem !important;
    }
}

@media screen and (max-width: 1360px){
    .profile_input_tester{
        width: 15rem !important;
    }
}

/* ecran  */
@media screen and (max-width: 768px){
    .profil_tester_container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .client-tester-profile{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 3rem;
        padding: 0 1rem;
    }
    .profil_card{
        background-color: #F4F7F7;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.158);
        padding: 4rem;
        margin: 3rem;
    }
    .profile_title{
        margin: 15rem 0 1rem 4rem;
        text-align: left;
        padding-left: 1rem
    }
}
