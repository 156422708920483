.navBar__horizontal {
    width: 100vw;
    min-height: 5em !important;
    background-color: #fff;
    position: fixed !important;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    padding: 0 !important;
    z-index: 11;
}

.navbar__color{
    background-color: #fff;
    position: fixed !important;
    z-index: 11;
}
.btn__sign{
    font-family: 'Roboto',sans-serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    background-color: #17A25C !important;
    border-color: #17A25C !important;
    border-radius: 8px !important;
    margin-right: 1rem;
    width: 11.5rem;
    height: auto;
    margin-top: .3em;
}
.btn__close{
    font-family: 'Roboto',sans-serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    background-color: #17A25C !important;
    border-color: #17A25C !important;
    border-radius: 8px !important;
    width: 8rem;
    height: auto;
}
.btn__sign2{
    font-family: 'Roboto',sans-serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    background-color: #433df620 !important;
    border-color: rgba(68, 61, 246, 0.1) !important;
    border-radius: 8px !important;
    color: #17A25C !important;
    width: 11.5rem;
    height: auto;
    margin-top: .3em;
}

.item__header {
    font-size: 1em;
    font-weight: 400;
    display: inline-block;
    font-family:  'Roboto',sans-serif !important;
}
.item__header_lang{
    font-size: 1em;
    text-align: end;
    font-weight: 400;
    display: inline-block;
    font-family:  'Roboto',sans-serif !important;
}

.item__header_connecter {
    font-size: 1em;
    font-weight: 400;
    display: inline-block;
    font-family:  'Roboto',sans-serif !important;
    color: #008000;
    text-decoration: underline;
}

.nav__bar{
    display: flex;
    flex-grow: 1;
}
.nav__bar div:nth-of-type(1) {
    margin-left: auto;
}
.nav__bar div:nth-of-type(6) {
    display: none;
}


.buttons_section{
    display: flex;
    justify-content: center;
    align-items: center;
}

.item__nav__bar {
    padding-top: 1.5em;
    width: auto;
    text-align: center; 
    color: #002c3f;
    font-family:  'Roboto',sans-serif !important;
    padding-right: 1.5rem !important;
}

.item__nav__bar__selected {
    padding-top: 1.5em;
    width: auto;
    color: #00a359 !important;
    font-family:  'Roboto',sans-serif !important;
    text-align: center;
    padding-right: 1.5rem !important;
}
.item__nav__bar_lang{
    margin-right: .5rem !important;
    width: 100%;
    height: 100%;
    text-align: end !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-family:  'Roboto',sans-serif !important;
    padding-right: 1rem !important;
}


.item__nav__bar:hover {
    color: #00a359;
    font-family:  'Roboto',sans-serif !important;
    padding-right: 1.5rem !important;
}

.item_blog{
    border-right: 2px solid #7c7c7c34;
    width: auto;
    margin-right: .5rem;
    padding-right: 3rem;
    font-size: 1.07em;
}

.navbar-nav{
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    padding-bottom: 1rem !important;
}
.btn__signup{
    background-color: #00a359 !important;
    border: none !important;
    border-radius: 30px !important;
    height: 55px;
    font-size: 13px;
    margin-top: .75em;
    width: 11em;
    font-family: 'Roboto',sans-serif !important;
}

.btn__sign:hover {
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    color: white;
}

.btn__sign2:hover {
    box-shadow:0 5px 20px rgba(0,44,63,0.2) !important ;
    color: #969696;
}

.logo{
    width: 100%;
    height: 100%;
    margin-left: 3rem;
}

.logo_container{
    height: 6.5rem;
    width: 15rem;
}

/*
   ***************************** MEDIA QUERIES********************************
*/

@media screen and (max-width: 1550px) {

    .logo_container{
        height: 8rem;
        width: 15rem !important;
    }
}



@media screen and (max-width: 1400px) {
    .navBar__horizontal {
        width: 100% !important;
    }

    .btn__sign{
        width: 100px;
        font-size: 12px !important;
    }
    .btn__sign2{
        width: 100px;
        font-size: 12px !important;
    }
    .logo{
        width: 90%;
        height: 90%;
        margin-left: 1rem;
    }
    
    .logo_container{
        height: 8rem;
        width: 13rem !important;
    }
}





@media screen and (max-width: 1200px) {
    .navBar__horizontal {
        width: 100% !important;
    }

    .btn__sign{
        width: 100px;
        font-size: 12px !important;
    }
    .btn__sign2{
        width: 100px;
        font-size: 12px !important;
    }
    .item__header_lang{
        display: flex;
        text-align: start;
    }
    .item__nav__bar_lang{
        display: flex;
        margin-right: 0;
        padding-right : .5rem;
        margin-bottom: -1rem;
    }
    .item__header_connecter{
        font-size : 1em;
    }
    .item__header{
        font-size : 1em;
    }
    .item_blog{
        border-right: 2px solid #7c7c7c34;
        width: auto;
        margin-right: 0rem;
        padding-right: .5rem;
        font-size: 1em;
    }
}



@media screen and (max-width: 1050px) {
    .logo_container{
        height: 8rem;
        width: 12rem !important;
    }
}


@media screen and (max-width: 950px) {

    .nav__bar div:nth-of-type(1) {
        margin-left: 0rem;
    }
    .buttons_section{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .5rem;
    }
    .item__nav__bar_lang{
        justify-content: center;
        align-items: center;
    }
}
    
