.table_container{
    background-color: wheat;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-collapse: separate;
    border-spacing: 0 1rem;
    width: 100%;
}   

.table_title{
    font-size: 1.8rem;
    color: #00A359;
    font-weight: 400;
    font-family: roboto;
    line-height: 25px;
    margin-bottom: 2rem
}