.btn__mobile {
  font-size: 1em !important;
  margin-bottom: 0.5em;
  margin-top: 2em;
  background: #00a359 !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 2rem !important;
  font-family: sans-serif;
}

.btn__mobile2 {
  font-size: 1em !important;
  margin-bottom: 0.5em;
  margin-top: 3.5em;
  background: #00a359 !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 2rem !important;
  font-family: sans-serif;
}

.video-react-big-play-button-left {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: block;
  position: absolute;
  margin: 4.7em 7.5em;
  padding: 0 !;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  transition: all 0.4s;
}

.pointer {
  cursor: pointer;
}

.MuiTableCell-paddingNone div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem !important;
}

.tableIcons1 {
  width: 1.8rem;
}
.tableIcons2 {
  width: 2rem;
}

.tableIconsDisabled {
  opacity: .2;
  cursor: not-allowed !important;
}


