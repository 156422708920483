.profil_testerC_container{
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    margin-left: -3rem; 
    margin-top: 3rem;
}
.client-testerC-profile{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.profile_container__header{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2.2rem;
}

.welcome-text-profil{
    font-weight: 700;
    font-size: 35px;
    font-family: 'Roboto', sans-serif;
    color: #00A359;
}

.profile-btn{
    background-color: rgba(189, 189, 189, 0);
    font-size: 0.9rem;
    color: #0D70E4;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: underline;
    font-family: 'Roboto', sans-serif;
}

.profil-text{
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;    
    font-weight: 400;
}

.profil_card{
    background-color: #F4F7F7;
    border-radius: 20px;
    padding: 2rem;
    margin: 3rem;
}
.updatePhotoText{
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;    
    font-weight: 400;
    font-style: italic;
}


.profile_title{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.modal_update_title_mdp{
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
    color: #00A359;
    font-weight : 700; 
    font-size : 18px
}

.modal_update_title{
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
    color: #00A359;
    font-weight : 700; 
    font-size : 20px
}

.btn-valider-profil{
    background-color: #00A359;
    color: white;
    border-radius: 10px !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.modal-header{
    border-bottom: none !important;
}

.pop-up-mdp-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.profile_img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}   

.profileImage{
    width: 100%;
    object-fit: contain !important;
  }

.pop-up-supp-text{
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;    
    font-weight: 300;
    width: 270px;
}
.title_s{
    width: 100%;
    height: 100%;
}
.title_s_style{
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pop-up-mdp-label{
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;    
    font-weight: 400;
}

.pop-up-mdp-input{
    width: 260px !important;
    border-radius: 10px !important;
}

.profile_input{
    width: 450px !important;
    outline: none;
    font-size: 1rem;
    border-radius: 10px !important;
    font-family: 'Roboto', sans-serif;
}

.profile_input_tester_Insight{
    width: 21.8rem !important;
    outline: none;
    font-size: 1rem;
    border-radius: 10px !important;
    font-family: 'Roboto', sans-serif;
    flex-wrap: wrap;
}



@media screen and (min-width: 2500px){
    .profile_input_tester_Insight{
        width: 40rem !important;
        outline: none;
        font-size: 1rem;
        border-radius: 10px !important;
        font-family: 'Roboto', sans-serif;
    }

}

@media screen and (min-width: 2000px){
    .profile_input_tester_Insight{
        width: 26.8rem !important;
        outline: none;
        font-size: 1rem;
        border-radius: 10px !important;
        font-family: 'Roboto', sans-serif;
    }

}

@media screen and (max-width: 1700px){
    .profile_input_tester_Insight{
        width: 17rem !important;
        outline: none;
        font-size: 1rem;
        border-radius: 10px !important;
        font-family: 'Roboto', sans-serif;
    }

}

@media screen and (max-width: 1400px){
    .profile_input_tester_Insight{
        width: 14rem !important;
        outline: none;
        font-size: 1rem;
        border-radius: 10px !important;
        font-family: 'Roboto', sans-serif;
    }

}


@media screen and (max-width: 1200px){
    .profile_input_tester_Insight{
        width: 10rem !important;
        outline: none;
        font-size: 1rem;
        border-radius: 10px !important;
        font-family: 'Roboto', sans-serif;
    }
}


@media screen and (max-width: 1000px){
    .profile_input_tester_Insight{
        width: 8rem !important;
        outline: none;
        font-size: 1rem;
        border-radius: 10px !important;
        font-family: 'Roboto', sans-serif;
    }
    .profil_testerC_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 5rem;
    }
    .profil_card{
        width: 30rem !important;

    }
}

/* ecran  */
@media screen and (max-width: 768px){
    .profil_tester_container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .client-tester-profile{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 3rem;
        padding: 0 1rem;
    }
    .profil_card{
        background-color: #F4F7F7;
        border-radius: 20px;
        padding: 4rem;
        margin: 3rem;
    }
    .profile_title{
        margin: 15rem 0 1rem 4rem;
        text-align: left;
        padding-left: 1rem
    }
    .profile_input_tester_Insight{
        text-align: center;
        width: 400px !important;
        outline: none;
        font-size: 1rem;
        border-radius: 10px !important;
        font-family: 'Roboto', sans-serif;
        flex-wrap: wrap;
    }
}