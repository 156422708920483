
.ctn__login {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
	background: linear-gradient(334deg, #fff, #fefeff, #00a359);
	background-size: 180% 180%;
	animation: gradient-animation 6s ease infinite;
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.footer__login{
    margin-top: 2.5em ;
    display: flex;
    flex-direction: column;
    
}

.form__login{
    align-items: center;
    margin: auto;
}

#password__login,
#email__login,
textarea {
    width: 350px; 
    font-size: 1em;
    height: 40px;
    border-radius: 19px;
    border: solid 1px #d3d3d3 !important;
    color: #002C3F;
  }


.line {
    border: 1px solid rgb(212, 211, 211) !important;
    min-width: 100%;
  }
.line3 {
    border: 1px solid rgb(212, 211, 211) !important;
    min-width: 99%;
  }
#login__input{
    height: 48px;
    margin-bottom: 1.2em;
    
}
#login__input2{
    height: 48px;
    margin-bottom: 1.2em;
    
}
#text__footer{
 font-size: 1.1em;
 font-weight: 400;
 font-family:   sans-serif;   
}
.form__login{
    display: flex;
    flex-direction: column;
}
#header__login{
    font-size: 1.1em;
    font-weight: bold;
    font-family:   sans-serif; 
}

.forget__password{
    color: #00a359 ;
    font-size: .9em !important;
    font-weight: 400;
    font-family:   sans-serif; 
    text-decoration: underline;
    cursor: pointer;
}

.login__button_disabled{
    width: 170px;
    margin-bottom: 1em;
    margin-top: 1em;
    background: #00a359 !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 6px !important;
    font-size: .9em !important;
    font-family:   sans-serif; 
}

.login__button{
    width: 170px;
    margin-bottom: 1em;
    margin-top: 1em;
    background: #00a359 !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 6px !important;
    font-size: .9em !important;
    font-family:   sans-serif; 
}

.login__button:hover {
    /* background-color: #03c56e  !important; */
    box-shadow:0 10px 25px rgba(0,44,63,0.2) !important ;
    color: white;
    cursor: pointer;
}

#feed{
    margin-top: -1.5em;
}

.label_input_modal_forgetPassword{
    font-size: 0.8em;
    font-weight: 400;
    font-family:   sans-serif; 
    color: #000;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0.5em;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.modal_forgetPassword{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modal_forgetPassword_btn{
    width: 120px;
    margin-bottom: 1em;
    margin-top: 1em;
    background: #17A25C !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 10px !important;
    font-size: 0.9em !important;
    font-family:'Roboto', sans-serif; 
    padding-left: 1rem;
}

.modal_forgetPassword_footer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.froget_modal_input{
    width: 100% !important;
    font-size: 1em;
    height: 40px !important;
    border-radius: 19px;
    border: solid 1px #d3d3d3 !important;
}