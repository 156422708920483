
.footer_container{
    background-color: #8d8d8d23;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    margin-top: 0rem;
    filter: drop-shadow(0 -1mm 1mm rgba(99, 99, 99, 0.288));    
}


.footer_content{
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-around;
    gap: 3rem;
    flex-wrap: wrap;
}

.footer_block{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.body__span{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-size: 16px;
    color: #000000;
    cursor: pointer !important;
    text-decoration: none;
}

.body__span:hover{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    cursor: pointer !important;
    text-decoration: none;
}

.body__line{
    text-decoration: underline;
    cursor: pointer;
}

.footer_title{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 800 !important;
    font-size: 18px;
    font-weight: 600;
    color: #00a359; 
    margin-bottom: 5px;
}

.bottom_line{
    display: flex;
    flex-direction: row;

}

.col_content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.col_content_footer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 5rem;
    padding-top: .5rem;
}

.col_content_footerr{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: .5rem;
}

.col_content_footer1{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
}

.btn_footer2{
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
    color: #fff;
    background-color: #00a359 !important;
    border: none !important;
    height: 35px;
    margin: 1em auto .5em auto;
    width: 20rem;
}

.btn_footer{
    border-radius: 5px;
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
    color: #000 !important;
    background-color: #fff !important;
    border: none !important;
    height: 35px;
    margin: 2em auto 2em auto;
    width: 20rem;
}



.footer_left{
    margin: 0 2rem .5rem 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.footer_font{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    text-align: center;
    color: rgba(0, 0, 0, 0.568);
}

.footer_flags{
    display: flex;
    align-items: center;
    gap: .5rem;
}

@media screen and (max-width :1300px) {
    .btn_footer2{
        font-size: 12px !important;
        width: 13rem;
    }

    .col_content_footer{
        padding-left: 2rem;
    }
    .btn_footer{
        font-size: 12px !important;
        width: 13rem;
    }
    .footer_content{
        gap: 0rem;
    }
}
@media screen and (max-width :1100px) {
    .footer_flags{
        flex-direction: column;
    }
}

@media screen and (max-width :450px) {
    .footer_left{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
    }
    .footer_left{
        margin: 0rem !important;
    }
}


@media screen and (max-width :600px) {
    .col_content_footer{
        padding-left: 0rem;
    }
    .footer_left{
        margin: 0 1rem 0 0;
    }
}