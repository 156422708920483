

.span_title{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    /* line-height: 30px; */
    text-align: center;
    font-family: 'Roboto', sans-serif;
}
.tester_1{
    width: 7rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    color: #00A359;
    margin-top: .1rem;
}

.alldata__container{
    display: flex;
    flex-direction: row; 
    gap : .8rem;
}

.allDataResponseLoadersContainer{
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap; 
}

.testeur-response-open {
    position: relative;
}
.allDataResponse{
    border: 5px solid #F3FBF7;
    border-radius: 10px;
    width: 98.9%;
    margin-left: 1.1rem;
}

.rightBorder{
    border-right: 5px solid #F3FBF7;
}

.testeur-response-open:not(:last-child)::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #00A359;
}

.allDataResponseLoaders{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.7rem;
    width: 100%;
}

.testeur-response-answer{
    display: flex;
}

.answerAndComment-step-section{
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.8;
    display: inline-block;
}

.testeur-response-answer-testerRep{
    margin-left: 2.5rem;
}
.testeur-response-answer-testerComm{
    margin-left: 1rem;
    text-align: left;
}

.etape-header-video-allData {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 .7rem;
    gap: 2rem;
    margin-top: 1rem;
}
.video_section_AD {
    display: flex;
    justify-content: center;
    align-items: center;
}



.first-resume-right{
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 0 16px;
}
.col-md-5{
    padding-left: 0 !important;
}