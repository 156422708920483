.statPanel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap : 1rem;
    width: 99%;
    /* margin-top: 1.1rem; */
}


.s-top-component-container-panel {
    height: 100px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 10px;
    background-color: white;
}

.titleStat {
    font-weight: 500;
    font-size: 2.5rem;
    font-family: 'Roboto', sans-serif;
    color: #00A359;
}

.statPanel_conatainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3px;
}

.panel_header_text {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: #000;
    font-weight: 500;
}

.panel_header_text_result {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: #000;
    margin-left: .5rem;
}

.panel-header {
    border-radius: 10px;
    background-color: white;
    padding: 1em 2em;
    border: 5px solid #F3FBF7;
    flex-wrap: wrap;
}

.panel_header_container {
    width: 97.6%;
    height: 5rem;
    border: 4px solid #F3FBF7;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4rem;
    padding-left: 1rem;
}

.filter_text_panel {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: #000;
    font-weight: 500;
    padding-top: .3rem !important;
}

.filter_section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4rem;
}

.filter_section_testeur {
    width: 11rem;
    height: 100%;
    border-radius: 15px;
    border: 4px solid #F3FBF7;
}

.shortcut_panel {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 1rem !important;
    margin-bottom: 2em;
    margin-right: 1em;
    margin-left: .5em;
    min-width: 146px;
    color: #002C3F !important;
    min-height: 38px;
    border: none !important;
    background-color: #F3FBF7 !important;
    border-radius: 5px !important;
}

.shortcutf_panel {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 1rem !important;
    margin-bottom: 2em;
    margin-right: 1em;
    margin-left: .5em;
    min-width: 146px;
    color: #002C3F !important;
    min-height: 38px;
    border: none !important;
    background-color: #F3FBF7 !important;
    border-radius: 5px !important;
}

.shortcut_panel:hover {
    box-shadow: 0 5px 20px rgba(0, 44, 63, 0.2) !important;
    color: #00a35ac9 !important;
    border: 1px solid #00a35ac9 !important;
}

.shortcut_panel.selected {
    box-shadow: 0 5px 20px rgba(0, 44, 63, 0.2) !important;
    color: #006f3d !important;
    border: 1px solid #006f3d !important;
}
.doughnutChart_noData {
    font-size: 1rem;
    width: 84%;
    height: 84%;
    font-weight: 600;
    display: flex;
    flex-direction: column;
}
.doughnutChart_noData img{
    width: 100%;
    height: 100%;
}

.filter_section_filter {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5rem;
    margin-top: .2rem !important;
}

.dashboard_panel_container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 20rem;
    row-gap: 3rem;
}

.panel_etapes {
    display: flex;
    flex-wrap: wrap;
}

.sub-sub-top-component-container-panel {
    display: flex;
    flex-direction: column;
}


.dashboard_panel_result {
    margin-left: 1rem;
}


.dashboard_panel_result_title {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 1rem 0 .5rem 2rem;
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    font-weight: 300;
}

.doughnutsChart_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 5rem;
    padding: 0 !important;
}

.doughnutChart_container {
    width: 65%;
    margin-left: 2rem;
}

.barChart_container {
    width: 86%;
    height: 73%;
    margin-left: .5rem;
}

.genderChart_container{
    width: 70%;
    margin-left: 2rem;
}

.gender-chart{
    width: 100%;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gender-chart-item{
    display: flex;
    align-items: center;
}

.gender_img{
    width: 7rem;
    height: 7rem;
}


.mapChart_container{
    width: 100%;
    height: 150%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

@media screen and (min-width: 1500px) {
    .doughnutChart_container {
        width: 55%;
        margin-left: 2rem;
    }
    
    .barChart_container {
        width: 70%;
        height: 73%;
        margin-left: .5rem;
    }
}


@media screen and (max-width: 1000px) {
    .doughnutChart_container {
        width: 75%;
        margin-left: 2rem;
    }
    
    .barChart_container {
        width: 95%;
        height: 73%;
        margin-left: .5rem;
    }
}