.modal_container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.model_inscription{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: -35px;
}

.btn__form__modal{
    text-decoration: none;
}

.btn__form__modal:hover{
    text-decoration: none;
}


.modal_inscription_admin{
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300 ;
    margin-top: -35px !important;
    padding: 0 2rem !important;
 }
 .header_popup{
    margin-bottom:  2rem;
 }